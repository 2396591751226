import { useApp } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useApp";
import { useStore } from "@/core/services/store";
import {
  loadProjects,
  setXentralConfig
} from "@/composables/useSalesChannelManagementApp";
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import { Toast } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { baseUrl } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/shopifyApp";
import { computed } from "vue";

export const useShopifyOauth = () => {
  const store = useStore();

  const xentralConfigData = computed(
    () => store.getters.xentralConfig?.data ?? []
  );
  const queryState = computed(() => xentralConfigData.value?.["state"] ?? []);

  const { selectIntegration } = useApp();
  const { getIntegrationsWithPresets } = useIntegration();
  const { show, startMigration } = useMigrationIntegration();

  function getAccessToken() {
    const state = store.getters.migrationOAuthState;

    let fromUrl =
      store.getters.xentralConfig?.data?.baseUrl?.replace(/\/$/, "") || "";

    if (window.location.href.includes("settings")) {
      fromUrl += "/app/app/connect-shopify?from_url=shopify/settings";
    } else {
      fromUrl += "/app/app/connect-shopify";
    }

    // Update migration state
    Object.assign(state, {
      migration: true,
      from_url: fromUrl,
      licenseId: store.getters.licenseId,
      project_id: store.getters?.selectedIntegration?.id
    });

    // Construct the OAuth URL
    const params = new URLSearchParams({
      no_redirect: true,
      client_id: process.env.VUE_APP_SHOPIFY_APP_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_SHOPIFY_APP_MIGRATION_REDIRECT_URL,
      state: btoa(JSON.stringify(state))
    });

    resetXentralConfig();

    // Redirect to the OAuth URL
    window.open(`${baseUrl}?${params}`, "_parent");
  }

  async function applyShopifyOAuthValues() {
    if (!queryState.value?.length) return;
    await loadProjects();

    if (queryState?.value.length && queryState.value !== "fetched") {
      show.value = true;
      return;
    }

    const currentSelectedIntegration = store.getters.selectedIntegration;

    await getIntegrationsWithPresets();
    await selectIntegration(
      currentSelectedIntegration,
      "settings",
      "settings",
      "wizard"
    );

    await Toast.fire({
      icon: "success",
      title: i18n.t("salesChannelManagementApp.newTokenSuccess")
    });
  }

  async function startShopifyOAuthMigration() {
    if (!queryState.value?.length) return;

    await startMigration(
      JSON.parse(atob(queryState.value.replaceAll("%3D", "=")))
    );
    await store.dispatch("setMigrationOAuthState", undefined);
  }

  function resetXentralConfig() {
    //Remove state property from xentralConfig vuex store
    let xentralData = store.getters.xentralConfig;

    // Create a new object excluding the 'state' property
    let newData = { ...xentralData.data };
    delete newData.state;

    let updatedXentralConfig = { ...xentralData, data: newData };

    setXentralConfig(updatedXentralConfig);
  }

  return {
    getAccessToken,
    applyShopifyOAuthValues,
    startShopifyOAuthMigration
  };
};
