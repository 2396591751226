export const baseUrl = "https://admin.shopify.com/admin/oauth/authorize";

export const shopifyApp = {
  id: 1,
  name: "shopify",
  useOAuth: true,
  showTestConnection: true,
  authModeToggle: true,
  label: "Shopify",
  logo: process.env.BASE_URL + "media/shopLogos/shopify.png",
  additional_params: {
    requestHeaders: {
      "X-Shopify-Access-Token": "{{token}}",
      "Content-Type": "application/json"
    },
    options: {
      json: {
        query: "query { shop { name } }"
      }
    },
    requestMethod: "POST",
    endpoint: "/admin/api/2024-04/graphql.json"
  },
  environmentVariables: {
    oauth: {
      canToggleLegacyConfig: true,
      client_id: process?.env?.VUE_APP_SHOPIFY_APP_CLIENT_ID,
      redirect_uri: process?.env?.VUE_APP_SHOPIFY_APP_APPLICATION_REDIRECT_URL,
      no_redirect: true
    },
    presetId: process.env?.VUE_APP_SCM_APP_SHOPIFY_PRESET_ID,
    testWorkflows: {
      product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
      salesOrder: process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_ORDER_WORKFLOW_ID,
      stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
      prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
      category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
    },
    testBatchWorkflows: {
      salesOrder:
        process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_ORDER_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_BATCH_TRACKING_WORKFLOW_ID
    },
    migrationWorkflows: {
      export: {
        all: process.env?.VUE_APP_SCM_APP_SHOPIFY_MIGRATION_EXPORT_WORKFLOW_ID,
        single:
          process.env
            ?.VUE_APP_SCM_APP_SHOPIFY_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
      },
      import: {
        all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
        single: process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
      }
    },
    xentralWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_XENTRAL_WORKFLOW_ID,
    CFLWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_CFL_WORKFLOW_ID,
    CFLDataSetId: process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_CFL_DATASET_ID,
    syncBatchWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_SYNC_BATCH_WORKFLOW_ID,
    shopImporterWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPIFY_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
  }
};
