import { computed, ref } from "vue";
import { useStore } from "@/core/services/store";
import _ from "lodash";
import { areasWithoutMigration } from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";
import router from "@/router";

const store = useStore();
const initialized = ref(false);

export const useAppStatus = () => {
  function toggleStatus(status) {
    initialized.value = status;
  }

  const isAppInitialized = computed(() => initialized.value);

  const isProductsSelected = integration => {
    return (
      (store.getters["variables/configValueByName"]("productsSelected")
        ?.value?.[integration.id] ??
        false) ||
      !hasProductSelection.value
    );
  };

  const oAuthState = store.getters.xentralConfig?.data?.["state"];

  // After successful oauth authorization, go back into installation progress
  if (oAuthState) {
    if (oAuthState?.["migration"]) {
      return;
    }
    router.push({ name: "salesChannelManagementAppWizard" });
  }

  const isMigrationFinished = _.memoize(function (integration) {
    return (
      (store.getters["variables/configValueByName"]("migrationChecks")?.value?.[
        integration.id
      ]?.migrationFinished ??
        false) ||
      !hasMigration.value // isMigrationFinished should always be true, if the current app doesn't support migrations
    );
  });

  const hasMigration = computed(
    () => !store?.getters?.areaBlacklist?.includes("migration") ?? true
  );

  const hasProductSelection = computed(
    () => !store?.getters?.areaBlacklist?.includes("productSelection") ?? true
  );

  const hasOnlyMigration = computed(() =>
    areasWithoutMigration.every(el =>
      store.getters?.areaBlacklist?.includes(el)
    )
  );

  return {
    toggleStatus,
    isAppInitialized,
    isProductsSelected,
    isMigrationFinished,
    hasMigration,
    hasOnlyMigration
  };
};
